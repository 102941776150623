import LeadingTitle from "../components/LeadingTitle";
import Divider from "../components/Divider";
import Project from "../components/Project";
import Paragraph from "../components/Paragraph";
import pkmis from "../assets/pkmi_logo.jpg";
import hklReferral from "../assets/hkl_referral.png";
import cmlReferral from "../assets/cml_referral.png";
import mienote from "../assets/mienote.png";
import prevoirmobile from "../assets/prevoir_mobile.png";
import insuranceTracking from "../assets/vf_insurance_tracking.png";

function Projects() {
  return (
    <div>
      <LeadingTitle title="Projects" />
      <Project name="In-house Core Insurance System | PKMIS" image={pkmis}>
        <p>
          PKMIS was built to manage Prevoir insurance information with important
          modules such as product, premium, insured & dependent, finance,
          hotline call record, claim, CUS, CRM, member upload​​ & IC printing,
          reporting, etc.
        </p>
        <p className="mt-2 font-semibold">Technologies:</p>
        <p>
          Asp.net C#, Microsoft SQL Server | MSSQL, SQL Server Reporting
          Services | SSRS
        </p>
      </Project>
      <Divider />
      <Project name="HKL Referral (Not public anymore)" image={hklReferral}>
        <p>
          HKL Referral App allows agent users to refer HKL client prospect to
          access PKMI insurance effectively.
        </p>
        <p className="mt-2 font-semibold">Technologies:</p>
        <p>Flask, Flutter | Dart, MSSQL, PostgresDB</p>
      </Project>

      <Divider />
      <Project name="CML Referral (Not public anymore)" image={cmlReferral}>
        <p>
          CML Referral App allows agent users to refer CML client prospect to
          access PKMI insurance effectively.
        </p>
        <p className="mt-2 font-semibold">Technologies:</p>
        <p>Flask, Flutter | Dart, MSSQL, PostgresDB</p>
      </Project>
      <Divider />
      <Project
        name="MIENOTE | Money Income Expense Note"
        image={mienote}
        link="https://play.google.com/store/apps/details?id=com.sopheak1970.mie_note"
      >
        <p>
          A money note tool for managing your income, expense, and budget
          efficiency <br />
          MIE note is a note tool for managing your INCOME, EXPENSE, and BUDGET
          efficiency <br />
          ❓ Free? yes, it is for everyone! 😃 <br />
          ❓ Required sign up? no, it is offline only! <br />
          ❓ Extract data? yes, extract in CSV (data backup)! <br />
          ❓ Multiple currencies? yes, why not! plus exchange rate configuration
          for your data consolidation! <br />
        </p>
        <p className="mt-2 font-semibold">Technologies:</p>
        <p>Flutter | Dart, SQLite</p>
      </Project>
      <Divider />
      <Project
        name="Prevoir Mobile"
        image={prevoirmobile}
        link="https://play.google.com/store/apps/details?id=com.imis_mobile.prevoir_mobile&hl=en"
      >
        <div>
          Prevoir mobile app is an easy tool for the insured and partner's
          staff.
          <Paragraph title="Insured Feature">
            The insured, who subscribes to Prevoir's insurance policy, can
            register and activate to use this tool by supplying the Phone Number
            and Insured Code(identity code for each insured provided by the
            Insurer). In the control panel page, insured is able to fetch the
            subscribed insurance policies, check the validity of insurance
            coverage, benefits as well as the waiting period applied. It also
            guide the insured how to claim. <br />
            <br />
            - Insurance Policy: Display all subscribed insurance policies and
            the detail coverages of each.
            <br /> - How to claim?: Description of claim process for cashless
            system.
            <br /> - About us: Short description about the Insurer and the
            contact.
          </Paragraph>
          <Paragraph title="Partner's User Feature">
            This mobile app tool allows the partner's staffs to register and
            activate the access right by supplying the Staff Code and Phone
            Number in order to get access to prospect referral features. <br />
            <br />
            - Referred Prospect List: allow user to create/update and list all
            referred prospects who interested in the Insurance Products.
            <br />
            - Prospect Summary: the summarized list by month for referred
            prospects who interested in buying Insurance Policies.
            <br />
            - Insurance Products: list of Insurance Products agreed between the
            Insurer and Partner to promote to the Partner's clients.
            <br />
            - Register Insured List: list the referred prospect who subscribed
            for the Insurance Policies.
            <br />- Supports: display the contact to support if needed.
          </Paragraph>
        </div>
        <p className="mt-2 font-semibold">Technologies:</p>
        <p>Flutter | Dart, SQLite, FastAPI, PostgresDB, MSSQL</p>
      </Project>

      <Divider />
      <Project
        name="Insurance Tracking"
        image={insuranceTracking}
        link="https://play.google.com/store/apps/details?id=com.tingmoung.vfi_mobile"
      >
        <div>
          Enroll insurance policy for individual or household and process claim
          submission <br />
          <br />
          Insurance Tracking: the main purpose of this app is to enroll
          insurance policy for individual or household under Vision Fund
          International operation and to process claim submission when risk
          incurred under their insurance coverage. The processes can be done
          through online or offline. <br />
          <br />
          <Paragraph title="Key Features">
            Enrollment: the staffs can enroll individual, or their dependents
            based on the underwriting criteria set out for each product. Each
            user can see:
            <br />
            <br />- Main summary of registered Insured by month and insurance
            product.
            <br />- List of registered insured with short information.
            <br />- Detail insured and their policy information.
            <br />
            Claim submission: the staffs can collect claim submission documents,
            record the claim, and take picture of claim documents to upload and
            submit the claim to the Insurers.
          </Paragraph>
        </div>
        <p className="mt-2 font-semibold">Technologies:</p>
        <p>FastAPI, Django, Flutter | Dart, MSSQL, PostgresDB</p>
      </Project>
    </div>
  );
}

export default Projects;
