import { Routes, Route } from "react-router-dom";

import NavBar from "./pages/NavBar";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import Divider from "./components/Divider";
import Resume from "./pages/Resume";
import NotFound from "./pages/NotFound";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import AuthHome from "./pages/AuthHome";
import { useDispatch } from "react-redux";
import { auth } from "./configs/firebase_config";
import { useEffect } from "react";
import { login } from "./store";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(auth.currentUser);
    dispatch(login(auth.currentUser));
  }, [dispatch]);

  const menuItems = [
    { title: "Resume", path: "/resume" },
    { title: "Projects", path: "/projects" },
    { title: "Contact", path: "/contact" },
    { title: "Inbox", path: "/inbox" },
  ];

  return (
    <>
      <div className=" container mx-auto px-4">
        <NavBar menuItems={menuItems} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/inbox" element={<AuthHome />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Divider />
        <Footer />
      </div>
    </>
  );
}

export default App;
