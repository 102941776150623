import Divider from "../components/Divider";
import Education from "./Education";
import LeadingTitle from "../components/LeadingTitle";
import WorkdingExperience from "./WorkingExperience";
import Skills from "./Skills";

function Resume() {
  return (
    <div>
      <LeadingTitle title="Resume" />
      <WorkdingExperience />
      <Divider />
      <Education />
      <Divider />
      <Skills />
    </div>
  );
}

export default Resume;
