import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";
import { auth } from "../configs/firebase_config";
import { signOut } from "firebase/auth";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getAuthError, logout } from "../store";

function NavBar({ menuItems }) {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => {
    return state.auth.user;
  });

  const renderMenuItems = menuItems.map((item) => {
    return (
      <NavLink
        key={item.title}
        to={item.path}
        className={({ isActive }) =>
          classNames(
            "md:px-3 py-2  cursor-pointer hover:text-red-400  font-semibold",
            {
              "font-bold text-red-400": isActive,
            }
          )
        }
      >
        {item.title}
      </NavLink>
    );
  });

  const handleLogOut = (event) => {
    signOut(auth)
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        dispatch(getAuthError(error));
      });
  };

  return (
    <nav className="md:flex md:justify-between md:items-center md:h-[60px]">
      <Link
        to="/"
        className="text-2xl font-semibold cursor-pointer flex gap-1 items-center hover:text-white"
      >
        <div className="h-[40px] w-[40px] rounded-full text-yellow-400 bg-red-400 text-lg flex justify-center items-center hover:text-white">
          {"</>"}
        </div>
        <div className="bg-green-400 skew-y-3 translate-y-2 md:w-[150px]">
          Sopheak Men
        </div>
      </Link>

      <div className="flex flex-col w-full p-3 border rounded-md mt-7 md:flex-row md:items-center md:mt-0 md:border-none md:justify-end">
        {renderMenuItems}
        {authUser && (
          <Button
            onClick={handleLogOut}
            className="px-3 py-1 bg-red-500 text-white !text-sm hover:text-black mt-3 md:mt-0"
          >
            Log Out
          </Button>
        )}
      </div>
    </nav>
  );
}

export default NavBar;
