import { createSlice } from "@reduxjs/toolkit";

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    messages: [],
    lastVisible: null,
    error: null,
  },
  reducers: {
    fetchMessages(state, action) {
      state.lastVisible = action.payload.lastVisible;
      state.messages = action.payload.messages;
      state.error = null;
    },
    fetchMoreMessages(state, action) {
      state.lastVisible = action.payload.lastVisible;
      state.messages.push(...action.payload.messages);
      state.error = null;
    },
    getMessageError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { fetchMessages, getMessageError } = messagesSlice.actions;
export const messagesReducer = messagesSlice.reducer;
