import classNames from "classnames";
import { IoMdClose } from "react-icons/io";

function Message({ status, onFinish }) {
  const classes = classNames(
    "p-5 rounded-md mb-10 text-white flex justify-between items-center",
    {
      "bg-red-400 ": status.error,
      "bg-green-400 ": !status.error,
    }
  );

  return (
    <div className={classes}>
      <div>{status.message}</div>
      <IoMdClose size={23} className="cursor-pointer" onClick={onFinish} />
    </div>
  );
}

export default Message;
