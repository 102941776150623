import classNames from "classnames";
import { ImSpinner11 } from "react-icons/im";

function Button({ children, className, onClick, rest, isLoading }) {
  const classes = classNames(
    "rounded border border-black p-4 text-xl font-semibold text-center",
    "cursor-pointer",
    "enabled:hover:bg-white",
    className,
    "disabled:opacity-25",
    "flex justify-center items-center"
  );

  const handleClick = (event) => {
    onClick(event);
  };

  return (
    <button
      disabled={isLoading}
      className={classes}
      onClick={onClick && handleClick}
      {...rest}
    >
      {isLoading ? <ImSpinner11 className="animate-spin" /> : children}
    </button>
  );
}

export default Button;
