import classNames from "classnames";
import { toCamelCaseString } from "../utils/string";

function TextAreaField({ label, register, validation, className, error }) {
  const classes = classNames("flex flex-col", className);

  const camelCaseLabel = toCamelCaseString(label);

  return (
    <div className={classes}>
      <label className="font-semibold mb-2">{label}</label>
      <textarea
        {...register(camelCaseLabel, { ...validation })}
        className="border border-gray-300 rounded-md w-full px-2"
        rows={5}
        placeholder={`Enter your ${label}`}
      />
      {error && <div className="text-red-600">{error.message}</div>}
    </div>
  );
}

export default TextAreaField;
