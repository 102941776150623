import EventInfo from "../components/EventInfo";
import SectionInfo from "../components/SectionInfo";

function Education() {
  return (
    <SectionInfo title="Education">
      <EventInfo eventTitle="2010-2013" title="Royal University Of Phnom Penh">
        - Bachelor: Computer Science and Engineering <br />- Skill: Programming
      </EventInfo>

      <EventInfo eventTitle="2010-2010" title="Institute Of Foreign Languages">
        - Major: Year Zero <br />- Skill: English
      </EventInfo>
    </SectionInfo>
  );
}

export default Education;
