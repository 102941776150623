function FooterItem({ title, text, children }) {
  const renderedTitle = title && <div className="font-bold">{title}</div>;

  return (
    <div>
      {renderedTitle}
      <div>
        {text}
        {children}
      </div>
    </div>
  );
}

export default FooterItem;
