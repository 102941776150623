function Paragraph({ title, text, children }) {
  return (
    <div className="leading-loose mb-2">
      <p className="font-semibold text-md">{title}</p>
      <div className="text-sm">
        {text}
        {children}
      </div>
    </div>
  );
}

export default Paragraph;
