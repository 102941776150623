import classNames from "classnames";

function Title({ children, className }) {
  const classes = classNames(
    "text-3xl font-semibold mb-5 text-red-400",
    className
  );
  return <h2 className={classes}>{children}</h2>;
}

export default Title;
