import { configureStore } from "@reduxjs/toolkit";
import {
  fetchMessages,
  getMessageError,
  messagesReducer,
} from "./slices/messages";
import { login, logout, getAuthError, authReducer } from "./slices/auth";

const store = configureStore({
  reducer: {
    messages: messagesReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export { store };
export { fetchMessages, getMessageError, login, logout, getAuthError };
