import { Link } from "react-router-dom";
import Button from "./Button";

function LinkButton({ children, className, to }) {
  return (
    <Link to={to}>
      <Button className={className}>{children}</Button>
    </Link>
  );
}

export default LinkButton;
