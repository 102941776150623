import EventInfo from "../components/EventInfo";
import SectionInfo from "../components/SectionInfo";

function Skills() {
  return (
    <SectionInfo title="Skills & Expertise">
      <EventInfo title="Front-End Development">
        - HTML | CSS | JavaScript <br />
        - Flutter & Dart <br />
        - ReactJs <br />
      </EventInfo>

      <EventInfo title="Back-End Development">
        - Flask
        <br />
        - FastAPI <br />
        - Django
        <br />- Asp.net & Asp.net Core 2.0
      </EventInfo>

      <EventInfo title="Programming Languages">
        - JavaScript
        <br />
        - Python
        <br />
        - C# <br />
        - VBA
        <br />- Swift
      </EventInfo>

      <EventInfo title="Reporting">
        - SSRS (SQL Server Reporting Services)
      </EventInfo>

      <EventInfo title="Database">
        - Microsoft SQL Server
        <br />- Postgres <br />- SQLite
      </EventInfo>
    </SectionInfo>
  );
}

export default Skills;
