import Title from "./Title";

function SectionInfo({ title, children }) {
  return (
    <div className="flex flex-col">
      <Title className="lg:flex-1">{title}</Title>
      <div className="lg:flex-1 flex flex-col gap-8">{children}</div>
    </div>
  );
}

export default SectionInfo;
