import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    error: null,
  },
  reducers: {
    login(state, action) {
      state.user = action.payload;
      state.error = null;
    },
    logout(state) {
      state.user = null;
      state.error = null;
    },
    getAuthError(state, action) {
      state.user = null;
      state.error = action.payload;
    },
  },
});

export const { login, logout, getAuthError } = authSlice.actions;
export const authReducer = authSlice.reducer;
