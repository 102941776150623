import { useEffect, useState } from "react";
import {
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from "firebase/firestore";
import { db } from "../configs/firebase_config";
import LeadingTitle from "../components/LeadingTitle";
import InboxMessageList from "./InboxMessageList";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages, getMessageError } from "../store";
import Button from "../components/Button";
import Skeleton from "../components/Skeleton";

function Inbox() {
  const [isRefresh, setIsRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const inbox = useSelector((state) => {
    return state.messages;
  });

  useEffect(() => {
    if (inbox.messages.length === 0 || isRefresh) {
      setIsLoading(true);
      setIsRefresh(false);

      fetchFirstMessages()
        .then((snapshots) => {
          const data = snapshots.docs.map((doc) => {
            console.log(typeof doc.data());
            return { ...doc.data(), id: doc.id };
          });
          const payload = {
            messages: data,
            lastVisible: snapshots.docs[snapshots.docs.length - 1],
          };
          dispatch(fetchMessages(payload));
        })
        .catch((error) => dispatch(getMessageError(error)))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [dispatch, inbox.messages.length, isRefresh]);

  const fetchFirstMessages = () => {
    const first = query(
      collection(db, "inbox"),
      orderBy("createdAt", "desc"),
      limit(15)
    );
    return getDocs(first);
  };

  //   const fetchNextMessages = () => {
  //     const next = query(
  //       collection(db, "inbox"),
  //       orderBy("createdAt", "desc"),
  //       startAfter(inbox.lastVisible),
  //       limit(15)
  //     );
  //     getDocs(next);
  //   };

  const handleRefresh = () => setIsRefresh(true);

  let content = null;
  if (isLoading) {
    content = <Skeleton times={5} className="w-full h-10" />;
  } else {
    content = <InboxMessageList messages={inbox.messages} />;
  }

  return (
    <div>
      <LeadingTitle title="Inbox" />
      <div className="mb-3">
        <Button
          onClick={handleRefresh}
          className="py-1 px-3 !text-[12px] border-gray-300"
          isLoading={isLoading}
        >
          Refresh
        </Button>
      </div>
      {content}
    </div>
  );
}

export default Inbox;
