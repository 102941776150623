import classNames from "classnames";
import myphoto from "../assets/sopheakmen.png";
import LinkButton from "../components/LinkButton";
import Paragraph from "../components/Paragraph";

function Home() {
  const responsiveClasses = classNames(
    "flex flex-col py-10 px-5 text-center",
    "lg:flex-row lg:justify-between lg:items-center lg:h-[90vh] lg:text-start"
  );

  return (
    <div className={responsiveClasses}>
      <div className="flex-1 flex flex-col justify-center items-center gap-7">
        <img
          className="rounded-full border border-black max-w-md"
          src={myphoto}
          alt="Profile"
        />
        <div className="flex gap-2">
          <LinkButton to="/resume" className="bg-yellow-400">
            Resume
          </LinkButton>
          <LinkButton to="/projects" className="bg-red-400">
            Projects
          </LinkButton>
          <LinkButton to="/contact" className="bg-blue-400">
            Contact
          </LinkButton>
        </div>
      </div>
      <div className="flex-1">
        <p className="font-bold text-9xl mb-6">Hello</p>
        <p className="font-semibold text-2xl mb-3">A Bit About Me</p>
        <div>
          <p className="leading-relaxed mb-2">
            Aspiring in MIS development with 8 year's experiences
          </p>
          <Paragraph
            title="1st experience in In-house core insurance system using:"
            text="- C#, Asp.net, Asp.net core, MSSQL, PostgresDB, SSRS report
            builder, Word, Excel, Power Point, Outlook, Skype, Microsoft Server
            2012 & 2016"
          />
          <Paragraph
            title="2nd experience in Mobile & API development using:"
            text="- Python, Flask, FastApi, Django, Flutter & Dart, PostgresDB,
              basic Docker, basic Git, Asana project management, basic Ubuntu
              server, basic Nginx"
          />
          <Paragraph
            title="Currently I am focusing on ReactJS web development:"
            text="I
            typically learn to build responsive, attractive, and reactive
            application using HTML, CSS, JavaScript with ReactJs to communicate
            with backend API or other services"
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
