function InboxMessageList({ messages }) {
  const renderedMessage = messages.map((msg) => {
    return (
      <div key={msg.id} className="p-5 border rounded-md mb-3 flex gap-3 ">
        {/* <input type="checkbox" /> */}
        <div className="flex-1">
          <div className="text-gray-500 text-[12px] flex justify-between">
            <div>
              Sender: {msg.from_name} | {msg.phone_number} | {msg.reply_to}
            </div>
            <div>{msg.createdAt.toDate().toLocaleDateString("en-US")}</div>
          </div>
          <div>{msg.message_id}</div>
          <div>Message: {msg.message}</div>
        </div>
      </div>
    );
  });

  return <div>{renderedMessage}</div>;
}

export default InboxMessageList;
