import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../configs/firebase_config";
import LeadingTitle from "../components/LeadingTitle";
import TextField from "../components/TextField";
import Button from "../components/Button";
import Message from "../components/Message";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthError, login, logout } from "../store";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state) => {
    return state.auth;
  });

  const {
    handleSubmit,
    formState: { errors },

    register,
  } = useForm();

  const handleLogin = (data) => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((credential) => {
        const user = credential.user;
        dispatch(login(user));
      })
      .catch((error) => {
        dispatch(getAuthError(error.message));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <LeadingTitle title="Login" />
      {authState.error && (
        <Message
          status={{
            error: authState.error ? true : false,
            message: authState.error,
          }}
          onFinish={() => dispatch(logout())}
        />
      )}
      <form onSubmit={handleSubmit(handleLogin)} className="lg:max-w-[400px]">
        <TextField
          label="Email"
          register={register}
          validation={{ required: "Email is required" }}
          className="mb-3"
          error={errors.email}
        />
        <TextField
          label="Password"
          register={register}
          validation={{ required: "Password is required" }}
          className="mb-3"
          error={errors.password}
          type="password"
        />
        <Button
          isLoading={isLoading}
          className="w-full py-2 px-3 text-center bg-red-400"
        >
          Login
        </Button>
      </form>
    </div>
  );
}

export default Login;
