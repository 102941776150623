import classNames from "classnames";
import { toCamelCaseString } from "../utils/string";

function TextField({ label, register, validation, className, error, type }) {
  const classes = classNames("flex flex-col", className);

  const camelCaseLabel = toCamelCaseString(label);

  return (
    <div className={classes}>
      <label className="font-semibold mb-2">{label}</label>
      <input
        {...register(camelCaseLabel, { ...validation })}
        placeholder={`Enter your ${label}`}
        className="border border-gray-300 rounded-md w-full h-10 px-2"
        type={type}
      />
      {error && <div className="text-red-600">{error.message}</div>}
    </div>
  );
}

export default TextField;
