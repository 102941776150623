import { Link } from "react-router-dom";
import LeadingTitle from "../components/LeadingTitle";

function NotFound() {
  return (
    <div className="h-[80vh] flex flex-col justify-center items-center">
      <LeadingTitle title="Sorry the page is not found!!!" />

      <p>
        Click{" "}
        <Link to="/" className="text-blue-500">
          Here!
        </Link>
      </p>
    </div>
  );
}

export default NotFound;
