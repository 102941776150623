import { Link } from "react-router-dom";

function Project({ name, children, image, link }) {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:flex-1 mb-7 md:mb-0">
        <p className="text-2xl mb-3 font-semibold text-orange-400">{name}</p>
        {children}
      </div>
      <div className="md:flex-1 flex justify-center items-start">
        {link ? (
          <Link target="_blank" to={link}>
            <img
              src={image}
              alt="Project"
              className="rounded-md max-w-xs drop-shadow-md "
            />
          </Link>
        ) : (
          <img
            src={image}
            alt="Project"
            className="rounded-md max-w-xs drop-shadow-md "
          />
        )}
      </div>
    </div>
  );
}

export default Project;
