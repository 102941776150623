import { useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Button from "../components/Button";
import FooterItem from "../components/FooterItem";
import LeadingTitle from "../components/LeadingTitle";
import TextAreaField from "../components/TextAreaField";
import TextField from "../components/TextField";
import { emailJsConfig } from "../configs/emailjs_config";
import { useState } from "react";
import Message from "../components/Message";
import { db } from "../configs/firebase_config";
import { collection, addDoc, Timestamp } from "firebase/firestore";

import short from "short-uuid";

function Contact() {
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    formState,
    register,
    reset,
  } = useForm();

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({
        fullname: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  }, [reset, formState]);

  const handleSubmitForm = (data) => {
    setIsLoading(true);
    const msgObj = {
      from_name: data.fullname,
      to_name: "Sopheak Men",
      message: data.message,
      reply_to: data.email,
      phone_number: data.phoneNumber,
      message_id: short.generate(),
    };
    emailjs
      .send(
        emailJsConfig.serviceId,
        emailJsConfig.templateId,
        msgObj,
        emailJsConfig.publicKey
      )
      .then(
        function (_) {
          addInboxDoc(msgObj);
        },
        function (error) {
          console.log(error);
          setStatus({
            message: `Sorry, unexpected error on sending email! please try again later!`,
            error: true,
          });
          setIsLoading(false);
        }
      );
  };

  const addInboxDoc = (msgObj) => {
    addDoc(collection(db, "inbox"), {
      ...msgObj,
      createdAt: Timestamp.fromDate(new Date()),
      isRead: false,
      isArchive: false,
    })
      .then((response) => {
        console.log("Document written with ID: ", response.id);
        setStatus({
          message:
            "Thank you for messaging us. I will reach out to you very soon.",
          error: false,
        });
      })
      .catch((error) => {
        console.log(error);
        setStatus({
          message: `The email was sent successfully with error logging (please ignore the error). I will reach out to you very soon.`,
          error: false,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <LeadingTitle title="Contact" />
      {status && <Message status={status} onFinish={() => setStatus(null)} />}
      <div className="flex flex-col md:flex-row">
        <div className="flex mb-10 flex-1 flex-col gap-5">
          <FooterItem title="Phone" text="+85578526653" />
          <FooterItem title="Email" text="sopheakmen1970@gmail.com" />
        </div>

        <div className="flex-1">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <TextField
              label="Fullname"
              register={register}
              validation={{ required: "Fullname is required" }}
              className="mb-3"
              error={errors.fullname}
            />
            <TextField
              label="Phone Number"
              register={register}
              validation={{ required: "Phone number is required" }}
              className="mb-3"
              error={errors.phoneNumber}
            />
            <TextField
              label="Email"
              register={register}
              validation={{
                required: "Email address is required",
                validate: {
                  matchPattern: (v) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "Email address must be a valid address",
                },
              }}
              className="mb-3"
              error={errors.email}
            />
            <TextAreaField
              label="Message"
              register={register}
              validation={{
                required: "Message is required",
              }}
              className="mb-3"
              error={errors.message}
            />

            <Button
              type="submit"
              className="bg-red-400 py-2 px-3 w-full"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
