import EventInfo from "../components/EventInfo";
import Paragraph from "../components/Paragraph";
import SectionInfo from "../components/SectionInfo";

function WorkdingExperience() {
  return (
    <SectionInfo title="Work Experience">
      <EventInfo eventTitle="2020-2022" title="Web/Mobile Developer">
        freelancer at Ting Moung Technology <br />
        - Analyze and design database structure <br />
        - Design user interface for mobile app <br />
        - Build web API for mobile app <br />
        - Build web admin for data configuration <br />
        - Build web portal for reporting <br />
        - Build mobile application <br />
        - Google play store deployment <br />
        - Maintenance <br />
      </EventInfo>
      <EventInfo
        eventTitle="2013-2020"
        title="MIS Developer -> Administrator -> Supervisor"
      >
        <Paragraph title="1. Key roles in System Development Life Cycle (SDLC)">
          Work closely with key project stakeholders and business people for
          collecting system specification and user requirement <br />
          - Design system new requirements & specify impacts against existing
          programs
          <br />
          - Conduct system analysis business analyst
          <br />
          - Analyze and design database structure/relationship, providing the
          data integrity, scalability and make sense of information spread out
          multiple databases, or in disparate tables or files
          <br />
          - Design user interface, write code and test, provide that system
          works smoothly and meets the business requirement
          <br />
          - Participate in system testing, user acceptance test and follow up on
          fixing of incidents
          <br />
          - Document technical specification, business specification, system
          integration test, unit testing, test case & test scenario
          <br />
          - Continually learn and evaluate new technologies and enhance your
          technical expertise
          <br />
        </Paragraph>
        <Paragraph title="2. Mobile Application development">
          - Design and develop mobile application (android or IOS) as required
          by the company <br />
          - Test, fix the error and modify the application to ensure that they
          operate reliably <br />
        </Paragraph>
        <Paragraph title="3. DBMS Administration">
          - Administer a variety of database systems including Microsoft SQL
          Server, PostgreSQL <br />
          - Ensure the accuracy of data by checking the collected data and
          process to necessary correction
          <br />- Liaise with the different departments to ensure accuracy and
          flow of information system
        </Paragraph>
      </EventInfo>
    </SectionInfo>
  );
}

export default WorkdingExperience;
