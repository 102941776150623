import Login from "./Login";
import Inbox from "./Inbox";
import { useSelector } from "react-redux";

function AuthHome() {
  const authUser = useSelector((state) => {
    return state.auth.user;
  });

  if (authUser) {
    return <Inbox />;
  }

  return (
    <div>
      <Login />
    </div>
  );
}

export default AuthHome;
