import { FaLinkedinIn, FaYoutube } from "react-icons/fa";
import FooterItem from "../components/FooterItem";
import { Link } from "react-router-dom";
import classNames from "classnames";

function Footer() {
  const classes = classNames(
    "flex flex-col gap-5 mb-10",
    "md:flex-row md:justify-between"
  );

  return (
    <div className={classes}>
      <FooterItem title="Phone" text="+85578526653" />
      <FooterItem title="Email" text="sopheakmen1970@gmail.com" />
      <FooterItem
        title="Follow Me"
        children={
          <div className="flex gap-2">
            <Link
              target="_blank"
              to="https://www.linkedin.com/in/sopheak-men-programmer/"
            >
              <FaLinkedinIn />
            </Link>
            <Link
              target="_blank"
              to="https://www.youtube.com/channel/UCDghcXWBIuY0_nXWv0sml_w"
            >
              <FaYoutube />
            </Link>
          </div>
        }
      />
      <FooterItem text="© 2023 By Sopheak Men." />
    </div>
  );
}

export default Footer;
