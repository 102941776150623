import Paragraph from "./Paragraph";

function EventInfo({ eventTitle, title, children }) {
  return (
    <div className="flex flex-col gap-3 md:flex-row">
      {eventTitle && (
        <p className=" text-gray-500 min-w-[120px]">{eventTitle}</p>
      )}

      <Paragraph title={title}>{children}</Paragraph>
    </div>
  );
}

export default EventInfo;
